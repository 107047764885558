<div class="flex flex-col gap-1 w-full">
  <h5>{{ to["label"] }}</h5>

  @if(to["explanation"] && formIsSubmit() && !to["showExplanation"]){
  <mat-slide-toggle [checked]="showExplanation" (change)="showExplanation = !showExplanation" color="primary">
    <b class="dark:text-white">Afficher l'explication </b>
  </mat-slide-toggle>
  }
  @if(to["explanation"] && formIsSubmit() && (to["showExplanation"] || showExplanation)){
  <mat-card class="mat-elevation-z0 py-1 px-2 my-3 glass-card" id="explanationCard">
    <mat-card-content class="flex flex-col gap-2">
      <div class="flex gap-3 items-center">
        <mat-icon svgIcon="question-mark-circle-light" color="accent"></mat-icon>
        <h5 class="mb-0 text-black dark:text-white">Explication des résultats :</h5>
      </div>
      <mat-divider></mat-divider>
      <p class="  text-black dark:text-white font-light">
        {{ to["explanation"] }}
      </p>
    </mat-card-content>
  </mat-card>
  }

  <div class="flex flex-col md:gap-3 w-full py-2">
    @for (option of fromOptionsList; track option; let i = $index) {
    <div class="flex flex-row gap-3 flex-1 p-2 " [ngClass]="{'pointer-events-none': absorbPointer,}">
      @if(selectedIndex != null && selectedIndex != null){
      <mat-card class="glass-card item p-0 cursor-pointer flex-1"
        [ngClass]="{'good-answer': option.answerState=='good'  ,'bad-answer': option.answerState=='bad','disable-answer': option.answerState=='disable'}"
        [ngStyle]=" dynamicCssStyle(i, 'from' )" (click)="updateSelectedIndex(option,i)">
        <mat-card-content class="content p-4">
          <p class="user-answer text-black dark:text-white"> {{ option.label }}</p>
        </mat-card-content>
      </mat-card>

      <mat-card class="glass-card item p-0 cursor-pointer flex-1"
        [ngClass]="{'good-answer': toOptionsList[randomToIndexes[i]].answerState=='good'  ,'bad-answer': toOptionsList[randomToIndexes[i]].answerState=='bad','disable-answer': toOptionsList[randomToIndexes[i]].answerState=='disable'}"
        (click)="verifAnswer(toOptionsList[randomToIndexes[i]],randomToIndexes[i])">
        <mat-card-content class="content p-4">
          <p class="user-answer"> {{ toOptionsList[randomToIndexes[i]].label }}</p>
        </mat-card-content>
      </mat-card>
      }

    </div>
    }

  </div>

</div>