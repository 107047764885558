<div class="flex flex-col gap-1 w-full">
  <h5>{{ to["label"] }}</h5>

  @if(to["explanation"] && formIsSubmit() && !to["showExplanation"]){
  <mat-slide-toggle [checked]="showExplanation" (change)="showExplanation = !showExplanation" color="primary">
    <b class="dark:text-white">Afficher l'explication </b>
  </mat-slide-toggle>
  }
  @if(to["explanation"] && formIsSubmit() && (to["showExplanation"] || showExplanation)){
  <mat-card class="mat-elevation-z0 py-1 px-2 my-3 glass-card" id="explanationCard">
    <mat-card-content class="flex flex-col gap-2">
      <div class="flex gap-3 items-center">
        <mat-icon svgIcon="question-mark-circle-light" color="accent"></mat-icon>
        <h5 class="mb-0">Explication des résultats :</h5>
      </div>
      <mat-divider></mat-divider>
      <p class="  text-black dark:text-white font-light">
        {{ to["explanation"] }}
      </p>
    </mat-card-content>
  </mat-card>
  }

  <div cdkDropListGroup class="flex flex-col-reverse md:flex-col gap-10 w-full py-2">
    <div class="sentence">
      <ng-container *ngFor="let part of questionDetails.questions; trackBy: trackByMethod; let i = index;let last=last">
        <span>{{part}}</span>
        @if (questionDetails.answers[i]) {
        <div cdkDropList [cdkDropListData]="questionDetails.answers[i]" class="blank-container glass-chip"
          id="QUESTION_{{i}}" (cdkDropListDropped)="drop($event,'source')" [ngClass]="showAnswersV2(i)"
          [cdkDropListSortingDisabled]="formIsSubmit()" (click)="removeElement(i)" [ngStyle]="{

          }">
          <span class="blank" cdkDrag [cdkDragDisabled]="formIsSubmit()">

            <span class="user-value"> {{ getLabel(questionDetails.answers[i][0]) }}</span>
            @if(formIsSubmit() && questionDetails.answers[i].length>0){
            <span class="ml-1">{{questionDetails.answers[i][0].answer}}</span>
            }
            <div class="invisible" *cdkDragPlaceholder></div>
            <mat-chip *cdkDragPreview>
              {{ getLabel(questionDetails.answers[i][0]) }}
            </mat-chip>
          </span>
        </div>
        }
      </ng-container>
    </div>

    <mat-card
      class="chipList glass-card w-full sticky top-0 max-h-52 p-2 z-50 overflow-y-scroll md:relative md:max-h-fit md:bg-transparent md:overflow-y-hidden"
      [ngClass]="{'hidden': formIsSubmit()}">
      <mat-card-header class="hidden sm:block">
        <mat-card-title>
          <p class="mb-0 text-black dark:text-white">
            Glisse et dépose les mots ci-dessous dans la zone correspondante :
          </p>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="padding: 16px !important;">
        <mat-chip-set [disabled]="disableForm" class="glass-chip min-h-11 flex-wrap" cdkDropList
          [cdkDropListData]="questionDetails.options" id="OPTION" (cdkDropListDropped)="drop($event,'answer')"
          [cdkDropListSortingDisabled]="formIsSubmit()">
          <div *ngFor="let option of questionDetails.options" cdkDrag [cdkDragDisabled]="formIsSubmit()">
            <mat-chip>
              {{ option.label }}
            </mat-chip>
            <div *cdkDragPlaceholder class="invisible"></div>
            <mat-chip *cdkDragPreview>
              {{ option.label }}
            </mat-chip>
          </div>
        </mat-chip-set>
      </mat-card-content>
    </mat-card>
  </div>
</div>